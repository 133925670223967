import React, { HTMLAttributes, ReactNode, RefObject } from "react";
import { DismissButton, FocusScope, mergeProps, useOverlay } from "react-aria";
import styled from "styled-components";
import { body1 } from "~/guidelines/Typography";

type Variant = "light" | "dark";

interface PopoverProps {
  overlayRef?: RefObject<HTMLDivElement>;
  overlayPositionProps?: HTMLAttributes<HTMLDivElement>;
  children: ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  variant?: Variant;
}

const Wrapper = styled.div<{ variant: Variant }>`
  position: absolute;
  z-index: 99999;
  width: 100%;
  margin: ${({ theme }) => theme.spacing(4, 0, 4, 0)};
  padding: 0;
  overflow-y: auto;
  background: ${({ theme, variant }) =>
    variant == "dark"
      ? theme.color.background.darkFirst
      : theme.color.background.lightFirst};
  box-shadow: ${({ theme }) => theme.shadow.navigation};
  border: 1px solid lightgray;
  border-radius: 4px;
  outline-color: ${({ theme }) => theme.color.brand.primary};

  ${body1};
`;

export function Popover(props: PopoverProps) {
  const defaultOverlayRef = React.useRef<HTMLDivElement>(null);

  const {
    overlayRef: overlayRef = defaultOverlayRef,
    overlayPositionProps = {},
    isOpen,
    onClose,
    children,
  } = props;

  const { overlayProps } = useOverlay(
    {
      isOpen,
      onClose,
      shouldCloseOnBlur: true,
      isDismissable: false,
    },
    overlayRef,
  );

  if (!isOpen) {
    return null;
  }

  return (
    <FocusScope>
      <Wrapper
        {...mergeProps(overlayProps, overlayPositionProps)}
        ref={overlayRef}
        data-testid="popover"
        variant={props.variant ?? "light"}
      >
        <DismissButton onDismiss={onClose} />
        {children}
      </Wrapper>
    </FocusScope>
  );
}
