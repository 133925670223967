import { t } from "@lingui/macro";
import React from "react";
import styled from "styled-components";
import { AVAILABLE_LOCALES, guardAvailableLocale } from "~/core/locale";
import { CountryFlagOfLocale } from "~/guidelines/CountryFlag";
import { Item, Select } from "~/guidelines/Form/Select/Select";

const localeLabels: Record<AVAILABLE_LOCALES, string> = {
  [AVAILABLE_LOCALES.en]: t`English`,
  [AVAILABLE_LOCALES.fr]: t`Français`,
};

const LocaleLabel = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export type LocaleSwitcherProps = {
  currentLocale: AVAILABLE_LOCALES;
  availableLocales: AVAILABLE_LOCALES[];
  onLocaleChange: (newLocale: AVAILABLE_LOCALES) => void;
  variant?: "light" | "dark";
};

const LocaleSwitcher = ({
  currentLocale,
  availableLocales,
  onLocaleChange,
  variant = "light",
}: LocaleSwitcherProps) => {
  return (
    <Select
      selectedKey={currentLocale}
      useFloatingLabel={false}
      variant={variant}
      border={false}
      onSelectionChange={(newLocale: unknown) => {
        guardAvailableLocale(newLocale);
        onLocaleChange(newLocale);
      }}
      aria-label={t`Change language`}
    >
      {availableLocales.map((oneAvailableLocale) => (
        <Item
          key={oneAvailableLocale}
          aria-label={localeLabels[oneAvailableLocale]}
        >
          <LocaleLabel>
            <CountryFlagOfLocale locale={oneAvailableLocale} />
            {localeLabels[oneAvailableLocale]}
          </LocaleLabel>
        </Item>
      ))}
    </Select>
  );
};

export default LocaleSwitcher;
