import { AriaListBoxOptions } from "@react-aria/listbox";
import { SelectState } from "@react-stately/select";
import React, { HTMLAttributes, RefObject } from "react";
import ListBox from "~/guidelines/Form/Select/ListBox/ListBox";
import { EstimatedItemHeightInPixel } from "~/guidelines/Form/Select/ListBox/ListBoxItem";
import { Popover } from "~/guidelines/Form/Select/ListBox/Popover";

const MaxItemInListBeforeScroll = 4;

type Variant = "light" | "dark";

export type DropdownListBoxProps<T> = {
  variant?: Variant;
  overlayRef?: RefObject<HTMLDivElement>;
  overlayPositionProps?: HTMLAttributes<HTMLDivElement>;
  options: AriaListBoxOptions<T>;
  state: SelectState<T>;
};

const DropdownListBox = ({
  overlayRef,
  overlayPositionProps,
  options,
  state,
  variant = "light",
}: DropdownListBoxProps<unknown>) => {
  return (
    <Popover
      overlayRef={overlayRef}
      overlayPositionProps={overlayPositionProps}
      isOpen={state.isOpen}
      onClose={state.close}
      variant={variant}
    >
      <ListBox
        options={options}
        state={state}
        maxHeight={MaxItemInListBeforeScroll * EstimatedItemHeightInPixel}
        variant={variant}
      />
    </Popover>
  );
};

export default DropdownListBox;
