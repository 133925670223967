import { SelectState } from "@react-stately/select";
import React, { useEffect } from "react";
import { ListBoxOption } from "~/guidelines/Form/Select/ListBox/ListBoxOption";
import { ListBoxSection } from "~/guidelines/Form/Select/ListBox/ListBoxSection";

export const EstimatedItemHeightInPixel = 42;

export type ListBoxItemProps<T> = {
  index: number;
  state: SelectState<T>;
  variant?: "dark" | "light";
};

/**
 * There is (at least) two other types of item in a select: `placeholder` and `loader`.
 * We are not using them for now so we do not render them.
 */
const ListBoxItem = React.forwardRef<HTMLDivElement, ListBoxItemProps<unknown>>(
  function ListBoxItemWithForwardedRef(
    { index, state, variant = "light" },
    forwardedRef,
  ) {
    const item = state.collection.at(index);

    useEffect(() => {
      if (item === null) {
        console.warn(
          `The list box item (option or section) at index "${index}" is null. This is not expected.`,
        );
      } else {
        if (typeof item.rendered !== "string" && item.textValue === undefined) {
          console.warn(
            `The list box item (option or section) "${item.key}"" have a complex render. You should provide a 'textValue' attribute for accessibility.`,
          );
        }
      }
    }, [index, item, item?.key, item?.rendered, item?.textValue]);

    if (item === null) {
      console.warn(
        `The list box item (option or section) at index "${index}" is null. This is not expected.`,
      );
      return <div ref={forwardedRef} />;
    }

    switch (item.type) {
      case "section":
        return (
          <ListBoxSection
            ref={forwardedRef}
            key={item.key}
            item={item}
            state={state}
          />
        );

      case "item":
        return (
          <ListBoxOption
            ref={forwardedRef}
            key={item.key}
            item={item}
            state={state}
            variant={variant}
          />
        );

      default:
        console.warn(
          `A ListBoxItem of type "${item.type}" have been skipped: this item type is not handled yet.`,
        );
        return <div ref={forwardedRef} />;
    }
  },
);

export default ListBoxItem;
