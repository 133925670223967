"use client";

import { PressEvent } from "@react-types/shared";
import Script from "next/script";
import React, { useCallback } from "react";
import { Button, ButtonProps } from "~/guidelines/Button";

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

const GTMId = process.env.GTM_ID;

export const sendGTMEvent = (
  eventName: string,
  eventData: Record<string, any> = {},
) => {
  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer.push({ event: eventName, ...eventData });
  } else {
    console.log({ event: eventName, ...eventData });
  }
};

export function useTrackClick(eventName = "click_reservation_cta") {
  return useCallback(
    (
      ctaName: string,
      ctaLocation?: string,
      onPress?: (e: PressEvent) => void,
    ) =>
      (e: PressEvent) => {
        sendGTMEvent(eventName, {
          cta_name: ctaName,
          cta_location: ctaLocation || "unknown",
          page_path: window.location.pathname,
        });

        if (onPress) onPress(e);
      },
    [eventName],
  );
}

export const GlobalGTMP = ({
  applicationEnvironment,
}: {
  applicationEnvironment: "development" | "production" | "test" | "storybook";
}) => {
  if (applicationEnvironment === "test") {
    return <div className="gtm-global" />;
  }

  return (
    <>
      <Script
        id="gtm-global"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTMId}');
        `,
        }}
      />
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${GTMId}`}
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
    </>
  );
};

interface TrackedButtonProps extends ButtonProps {
  ctaName: string;
  ctaLocation?: string;
  gaEventName?: string;
}

export function TrackedButton({
  ctaName,
  ctaLocation,
  onPress,
  gaEventName,
  ...props
}: TrackedButtonProps) {
  const trackClick = useTrackClick(gaEventName);

  return (
    <Button {...props} onPress={trackClick(ctaName, ctaLocation, onPress)} />
  );
}
