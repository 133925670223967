import styled, { css, keyframes } from "styled-components";
import IconSvg from "~/guidelines/Icon/IconSvg";
import {
  buttonLarge,
  buttonNormal,
  buttonSmall,
} from "~/guidelines/Typography";

const rotatingSpinner = keyframes`
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  /**
   * Hacky but necessary to avoid issue with Safari outline
   * @see https://mui.com/components/buttons/#loading-button
   */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const SpinnerLoader = styled.div`
  width: 16px;
  height: 16px;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: ${rotatingSpinner} 1s ease infinite;
`;

export const LargeSpinnerLoader = styled.div`
  width: 36px;
  height: 36px;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: ${rotatingSpinner} 1s ease infinite;
`;

export const styledSize = (size: "small" | "normal" | "large") => {
  switch (size) {
    case "small":
      return css`
        gap: 2px;
        height: 24px;
        padding: 0 8px;
        border-width: 1px;

        ${buttonSmall};

        & ${IconSvg} {
          width: 16px;
        }

        & ${SpinnerContainer} {
          width: 16px;
          height: 16px;
        }
      `;
    case "normal":
      return css`
        gap: 4px;
        height: 36px;
        padding: 0 16px;
        border-width: 2px;

        ${buttonNormal};

        & ${IconSvg} {
          width: 16px;
        }

        & ${SpinnerContainer} {
          width: 16px;
          height: 16px;
        }
      `;
    case "large":
      return css`
        gap: 8px;
        height: 48px;
        padding: 0 32px;
        ${buttonLarge};
        border-width: 3px;

        & ${IconSvg} {
          width: 24px;
        }

        & ${SpinnerContainer} {
          width: 24px;
          height: 24px;
        }
      `;
  }
};

export const styledKind = (
  kind: "primary" | "secondary" | "tertiary" | "quaternary",
  variant: "light_background" | "dark_background",
) => {
  switch (kind) {
    case "primary":
      return css`
        background-color: ${({ theme }) =>
          variant === "light_background"
            ? theme.color.background.darkTertiary
            : theme.color.background.lightFirst};
        color: ${({ theme }) => theme.color.text.lightSecond};

        &:hover:not(:disabled) {
          background-color: ${({ theme }) =>
            variant === "light_background"
              ? theme.color.brand.tertiaryStrong
              : theme.color.background.lightFirst + "E5"};
        }

        border-color: ${({ theme }) =>
          variant === "light_background"
            ? theme.color.text.lightFirst
            : theme.color.background.darkTertiary};

        & ${SpinnerLoader} {
          border-top-color: ${({ theme }) => theme.color.text.lightSecond};
        }
      `;
    case "secondary":
      return css`
        background-color: ${({ theme }) =>
          variant === "light_background"
            ? theme.color.background.darkFirst
            : theme.color.background.lightFirst};
        color: ${({ theme }) =>
          variant === "light_background"
            ? theme.color.text.darkFirst
            : theme.color.text.lightFirst};
        border: none;
        box-shadow: ${({ theme }) =>
          css`-1px 0px 0px 0px ${theme.color.background.darkFirst} inset,
          1px 0px 0px 0px ${theme.color.background.darkFirst} inset,
          0px 1px 0px 0px ${theme.color.background.darkFirst} inset,
          0px -1px 0px 0px ${theme.color.background.darkFirst} inset;`};

        &:hover:not(:disabled) {
          background-color: ${({ theme }) =>
            variant === "light_background"
              ? theme.color.background.darkPrimary
              : theme.color.background.lightFirst + "E5"};
        }

        & ${SpinnerLoader} {
          border-top-color: ${({ theme }) =>
            variant === "light_background"
              ? theme.color.text.darkFirst
              : theme.color.text.lightFirst};
        }
      `;
    case "tertiary":
      return css`
        background-color: transparent;
        border-color: ${({ theme }) =>
          variant === "light_background"
            ? theme.color.text.lightFirst
            : theme.color.text.darkSecond};
        color: ${({ theme }) =>
          variant === "light_background"
            ? theme.color.text.lightFirst
            : theme.color.text.darkFirst};

        &:hover:not(:disabled) {
          background-color: ${({ theme }) =>
            variant === "light_background"
              ? theme.color.background.darkFirst + "1A"
              : theme.color.background.lightFirst + "1A"};
        }

        & ${SpinnerLoader} {
          border-top-color: ${({ theme }) =>
            variant === "light_background"
              ? theme.color.text.lightFirst
              : theme.color.text.darkFirst};
        }
      `;
    case "quaternary":
      return css`
        background-color: transparent;
        color: ${({ theme }) =>
          variant === "light_background"
            ? theme.color.text.lightFirst
            : theme.color.text.darkFirst};
        border: none;
        box-shadow:
          -1px 0px 0px 0px transparent inset,
          1px 0px 0px 0px transparent inset,
          0px 1px 0px 0px transparent inset,
          0px -1px 0px 0px transparent inset;
        padding-left: 0px;
        padding-right: 0px;

        &:hover:not(:disabled) {
          text-decoration-line: underline;
        }

        & ${SpinnerLoader} {
          border-top-color: ${({ theme }) =>
            variant === "light_background"
              ? theme.color.text.lightFirst
              : theme.color.text.darkFirst};
        }
      `;
  }
};

export const styledIconOnly = (
  isIconOnly: boolean,
  size: "small" | "normal" | "large",
) => {
  if (!isIconOnly) return css``;

  switch (size) {
    case "small":
      return css`
        border-radius: 100%;
        padding: 3px;
        width: 24px;
      `;
    case "normal":
      return css`
        border-radius: 100%;
        padding: 8px;
        width: 36px;
      `;
    case "large":
      return css`
        border-radius: 100%;
        padding: 9px;
        width: 48px;
      `;
  }
};

export const defaultButtonStyle = (fluid: boolean | undefined) => css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${fluid ? "100%" : "fit-content"};
  border-radius: 52px;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  transition:
    background-color 250ms ease-out,
    opacity 250ms ease-out,
    box-shadow 250ms ease-out;
  position: relative;
  border-style: solid;
  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;
