"use client";

import { SSRProvider } from "@react-aria/ssr";
import { addBreadcrumb as sentryAddBreadcrumb } from "@sentry/nextjs";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { useEffect, useMemo, useState } from "react";
import { ThemeProvider } from "styled-components";
import { options as auth0Options } from "~/clients/Auth0";
import { queryClient } from "~/clients/ReactQuery";
import { GlobalGTMP } from "~/components/Tracking/GoogleTagManager";
import { GlobalWIHPTracker } from "~/components/Tracking/WIHPTracker";
import { SanityClientContext } from "~/core/useImageUrlBuilder";
import {
  FakePageLoadingProgressBar,
  useFakePageLoadingProgressBar,
} from "~/guidelines/FakePageLoadingProgressBar";
import { ModalProvider } from "~/guidelines/Modal";
import { GlobalFontFaces } from "~/guidelines/Theme/fonts";
import { theme } from "~/guidelines/Theme/useTheme";
import LocaleConflict from "~/localization/LocaleConflictResolver";
import {
  Auth0Provider,
  Auth0ProviderOptions,
  FakeAuth0Provider,
} from "~/packages/auth0-react-passwordless";

const GlobalStyles = () => (
  // eslint-disable-next-line react/no-unknown-property
  <style jsx global>{`
    html,
    body {
      margin: 0;
      padding: 0;
      height: 100%;
    }

    #__next {
      height: 100%;
    }

    * {
      box-sizing: border-box;
    }

    *:focus {
      outline: none;
    }

    a:focus-visible.focus-ring,
    button:focus-visible.focus-ring,
    [role="button"]:focus-visible.focus-ring,
    input[type="radio"]:focus-visible.focus-ring,
    input[type="checkbox"]:focus-visible.focus-ring {
      border: 1px solid ${theme.color.brand.primary};
      box-shadow:
        -1px 0px 0px 0px ${theme.color.brand.primary} inset,
        1px 0px 0px 0px ${theme.color.brand.primary} inset,
        0px 1px 0px 0px ${theme.color.brand.primary} inset,
        0px -1px 0px 0px ${theme.color.brand.primary} inset;
    }
  `}</style>
);

const handleAuthenticationEvolve: Auth0ProviderOptions["onAuthenticationEvolve"] =
  (event, who = undefined, context: Record<string, unknown> = {}) => {
    sentryAddBreadcrumb({
      type: "debug",
      category: "authentication",
      data: { event, who, context },
      level: "debug",
    });
  };

const ApplicationKernel = ({
  children,
  applicationEnvironment = process.env.NODE_ENV === "production"
    ? "production"
    : process.env.NODE_ENV === "test"
      ? "test"
      : "development",
}: {
  children: React.ReactNode;
  applicationEnvironment: "production" | "test" | "development" | "storybook";
}) => {
  const { isRouteChanging, loadingKey } = useFakePageLoadingProgressBar();

  const [showReactQueryDevTool, setShowReactQueryDevTool] = useState(false);

  const AuthenticationProvider = useMemo(
    () =>
      applicationEnvironment === "storybook" ||
      applicationEnvironment === "test"
        ? FakeAuth0Provider
        : Auth0Provider,
    [applicationEnvironment],
  );

  useEffect(() => {
    if (applicationEnvironment === "development") {
      setShowReactQueryDevTool(true);
    }
  }, [applicationEnvironment]);

  return (
    <SSRProvider>
      <AuthenticationProvider
        {...auth0Options}
        onAuthenticationEvolve={handleAuthenticationEvolve}
      >
        <QueryClientProvider client={queryClient}>
          <SanityClientContext.Provider
            value={{
              projectId:
                applicationEnvironment === "storybook"
                  ? process.env.STORYBOOK_SANITY_PROJECT_ID
                  : process.env.SANITY_PROJECT_ID,
              dataset:
                applicationEnvironment === "storybook"
                  ? process.env.STORYBOOK_SANITY_DATASET
                  : process.env.SANITY_DATASET,
              baseUrl:
                applicationEnvironment === "storybook"
                  ? "https://fake-sanity-image-cdn.gogaille.vercel.app/proxy"
                  : undefined,
            }}
          >
            <GlobalGTMP applicationEnvironment={applicationEnvironment} />
            <GlobalWIHPTracker
              applicationEnvironment={applicationEnvironment}
            />
            <ThemeProvider theme={theme}>
              {process.env.NODE_ENV !== "test" ? <GlobalStyles /> : null}
              {process.env.NODE_ENV !== "test" ? <GlobalFontFaces /> : null}

              <FakePageLoadingProgressBar
                isRouteChanging={isRouteChanging}
                loadingKey={loadingKey}
              />
              <ModalProvider>
                {applicationEnvironment === "test" ? (
                  children
                ) : (
                  <LocaleConflict>{children}</LocaleConflict>
                )}
              </ModalProvider>
            </ThemeProvider>
          </SanityClientContext.Provider>

          {applicationEnvironment === "development" && showReactQueryDevTool ? (
            <ReactQueryDevtools initialIsOpen={false} />
          ) : null}
        </QueryClientProvider>
      </AuthenticationProvider>
    </SSRProvider>
  );
};

export default ApplicationKernel;
