import Cookies from "js-cookie";
import { useRouter } from "next/compat/router";
import React, { ReactNode } from "react";
import { homepageRoute } from "~/core/routes";
import LanguageBar from "~/localization/LocaleConflictResolver/LanguageBar";
import { useLocaleConflict } from "~/localization/LocaleConflictResolver/useLocaleConflict";

const excludedPaths = () => {
  const paths = new Set<string>([]);

  paths.add(homepageRoute());

  return paths;
};

type Props = {
  children: ReactNode;
};

const LocaleConflict = ({ children }: Props) => {
  const router = useRouter();
  const { requestLocale, isSameLocale } = useLocaleConflict();

  const localeConflict = Cookies.get("localeConflict");

  if (
    !router ||
    excludedPaths().has(router.asPath) ||
    isSameLocale ||
    localeConflict === "false"
  ) {
    return <>{children}</>;
  }

  return (
    <>
      <LanguageBar requestLocale={requestLocale} />
      {children}
    </>
  );
};

export default LocaleConflict;
