import NiceModal from "@ebay/nice-modal-react";
import { useDialog } from "@react-aria/dialog";
import { FocusScope } from "@react-aria/focus";
import {
  OverlayProvider,
  useModal,
  useOverlay,
  usePreventScroll,
} from "@react-aria/overlays";
import { AriaDialogProps } from "@react-types/dialog";
import { ModalProps as BaseModalProps } from "@react-types/overlays";
import React, { useEffect } from "react";
import styled from "styled-components";
import { IconOnlyButton } from "~/guidelines/Button";
import { NavigationClose } from "~/guidelines/Icon";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import { body1, heading6 } from "~/guidelines/Typography";

const ModalBackground = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.color.overlay.darkFirst};

  ${mediaQueries.md()} {
    align-items: center;
    justify-content: center;
  }
`;

const StyledOverlayProvider = styled(OverlayProvider)`
  height: 100%;
`;

const ModalProvider = ({ children }: { children: React.ReactNode }) => (
  <StyledOverlayProvider>
    <NiceModal.Provider>{children}</NiceModal.Provider>
  </StyledOverlayProvider>
);

export { ModalProvider };

const StyledModal = styled.div<{ size: string }>`
  width: 100%;
  overflow: visible;

  ${mediaQueries.md()} {
    width: ${({ size }) => (size === "auto" ? size : "500px")};
  }
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.lightFirst};
  ${body1};
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  box-shadow: ${({ theme }) => theme.shadow.base};
  overflow: hidden;

  ${mediaQueries.md()} {
    max-height: calc(100vh - ${({ theme }) => theme.spacing(8)});
    max-height: calc(100dvh - ${({ theme }) => theme.spacing(8)});
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px 0;
  gap: 16px;
  ${heading6};

  ${mediaQueries.md()} {
    padding-top: 0;
    padding-right: 0;
    display: grid;
    grid-template-columns: 1fr 165px 70px;
    padding-left: 40px;
    background-color: ${({ theme }) => theme.color.background.darkFirst};
    color: ${({ theme }) => theme.color.text.darkFirst};
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 0;
`;

const IllustrationBox = styled.div`
  display: none;
  ${mediaQueries.md()} {
    display: flex;
    align-items: flex-end;
  }
`;

const CloseButtonBox = styled.div`
  display: none;
  ${mediaQueries.md()} {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
  }
`;

const Content = styled.div`
  flex-shrink: 1;
  overflow: auto;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 16px;
`;

type Override<T1, T2, T3> = Omit<Omit<T1, keyof T2>, keyof T3> & T2 & T3;

type ModalProps = Override<
  BaseModalProps,
  AriaDialogProps,
  {
    title: string;
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    primaryAction?: React.ReactNode;
    secondaryAction?: React.ReactNode;
    isDismissable?: boolean;
    isCloseable?: boolean;
    headerIcon?: React.ReactNode;
    onAfterClose?: () => void;
    size?: "auto" | "fullscreen" | "default";
  }
>;

const Modal = ({ isCloseable = true, ...props }: ModalProps) => {
  const ref = React.useRef(null);
  const {
    title,
    isOpen,
    onClose,
    children,
    primaryAction,
    secondaryAction,
    headerIcon,
    onAfterClose,
    size = "default",
  } = props;

  usePreventScroll({ isDisabled: !isOpen });
  const { overlayProps, underlayProps } = useOverlay(props, ref);
  const { modalProps } = useModal();
  const { dialogProps, titleProps } = useDialog(props, ref);

  useEffect(() => {
    if (!isOpen && onAfterClose) {
      onAfterClose();
    }
  }, [isOpen, onAfterClose]);

  return isOpen ? (
    <ModalBackground {...underlayProps}>
      <FocusScope contain autoFocus>
        <StyledModal
          {...overlayProps}
          {...dialogProps}
          {...modalProps}
          size={size}
          ref={ref}
          data-testid="modal"
        >
          <Container>
            <Header>
              <Title {...titleProps}>{title}</Title>
              <IllustrationBox>
                {!headerIcon ? undefined : headerIcon}
              </IllustrationBox>
              {isCloseable ? (
                <CloseButtonBox>
                  <IconOnlyButton
                    kind="tertiary"
                    onPress={() => onClose()}
                    icon={<NavigationClose />}
                  />
                </CloseButtonBox>
              ) : undefined}
            </Header>

            <Content>{children}</Content>

            {primaryAction || secondaryAction ? (
              <Footer>
                {primaryAction}
                {secondaryAction}
              </Footer>
            ) : null}
          </Container>
        </StyledModal>
      </FocusScope>
    </ModalBackground>
  ) : (
    <></>
  );
};

export default Modal;
