import dynamic from "next/dynamic";
import React, { RefObject } from "react";
import { SheetDetent } from "react-modal-sheet/dist/types";
import {
  DrawerBackgroundColor,
  DrawerVariant,
  OpeningDirectionOnDesktop,
} from "~/guidelines/Drawer/DrawerOptions";
import { useIsMdScreen } from "~/guidelines/Theme/mediaQueries";

const BottomSheetDynamic = dynamic(
  () => import("~/guidelines/Drawer/BottomSheet"),
  { ssr: false },
);

const SideSheetDynamic = dynamic(
  () => import("~/guidelines/Drawer/SideSheet"),
  { ssr: false },
);

export type DrawerProps = {
  isOpen: boolean;
  openFrom?: OpeningDirectionOnDesktop;
  variant?: DrawerVariant;
  backgroundColor?: DrawerBackgroundColor;
  title: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
  onClose: () => void;
  onAfterClose: () => void;
  headerColor?: string;
  detent?: SheetDetent;
  closeButtonRef?: RefObject<HTMLButtonElement>;
};

const Drawer = ({
  openFrom = "right",
  isOpen,
  variant = "legacy",
  backgroundColor = "lightFirst",
  title,
  header = null,
  footer = null,
  onClose,
  onAfterClose,
  children,
  headerColor,
  detent = "content-height",
  closeButtonRef,
}: DrawerProps) => {
  const isMdScreen = useIsMdScreen();

  return isMdScreen ? (
    <SideSheetDynamic
      openFrom={openFrom}
      isOpen={isOpen}
      variant={variant}
      backgroundColor={backgroundColor}
      title={title}
      header={header}
      footer={footer}
      onClose={onClose}
      onAfterClose={onAfterClose}
      closeButtonRef={closeButtonRef}
      isDismissable
    >
      {children}
    </SideSheetDynamic>
  ) : (
    <BottomSheetDynamic
      isOpen={isOpen}
      isDismissable={true}
      backgroundColor={backgroundColor}
      header={header}
      footer={footer}
      onClose={onClose}
      onAfterClose={onAfterClose}
      headerColor={headerColor}
      detent={detent}
      closeButtonRef={closeButtonRef}
    >
      {children}
    </BottomSheetDynamic>
  );
};

export default Drawer;
