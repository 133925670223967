import { Breakpoints } from "~/guidelines/Theme/Breakpoints";

const breakpoints: Breakpoints = {
  xs: "360px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1400px",
};

const theme = {
  breakpoints,

  color: {
    brand: {
      primary: "#252321",
      primaryStrong: "#1B1918",
      secondary: "#F4CDCC",
      secondaryStrong: "#EBA5A3",
      tertiary: "#E987AF",
      tertiaryStrong: "#E15B92",
    },

    text: {
      lightFirst: "#1B1918",
      lightSecond: "#1B1918F2",
      lightThird: "#1B1918CC",
      lightDisabled: "#1B191899",
      lightTertiary: "#D93075",
      darkFirst: "#E5D7B9",
      darkSecond: "#E5D7B9F2",
      darkThird: "#E5D7B9CC",
      darkTertiary: "#E5D7B9CC",
      secondaryStrong: "#EBA5A3",
    },

    background: {
      lightFirst: "#FFFFFF",
      lightSecond: "#F3EDDF",
      lightThird: "#EAE0C7",
      lightSecondary: "#F4CDCC",
      lightDisabled: "#1B19181A",
      darkFirst: "#1B1918",
      darkSecond: "#252321",
      darkPrimary: "#000000",
      darkTertiary: "#E987AF",
      darkDisabled: "#1B19181A",
    },

    line: {
      lightPrimary: "#1B1918F2",
      lightTertiary: "#E987AF",
      lightFirst: "#EBEAE7",
      lightSecond: "#DDDAD5",
      darkFirst: "#E5D7B91A",
      darkSecond: "#E5D7B9E6",
    },

    feedback: {
      success: "#219653",
      successBis: "#4ECB71",
      successLight: "#85FAB7B2",
      successExtraLight: "#85FAB73B",
      error: "#E03C31",
      info: "#252321",
      warning: "#FE7624",
      warningLight: "#FE76243B",
    },

    custom: {
      green: "#E3AE46",
    },

    overlay: {
      darkFirst: "#1B191866",
    },
  },

  borderRadius: {
    small: "4px",
    default: "6px",
    large: "8px",
    xlarge: "16px",
    xxlarge: "32px",
  },

  shadow: {
    lineTop:
      "rgba(46, 59, 66, 0.125) 0px -1px 0px 0px, rgb(255, 255, 255) 0px 2px 0px 0px",
    paper:
      "0px 0px 4px rgba(0, 0, 0, 0.07), 0px 4px 40px 30px rgba(0, 0, 0, 0.04)",
    base: "0px 34px 60px -20px rgba(0, 0, 0, 0.1)",
    navigation:
      "0px 0px 3px rgba(104, 85, 39, 0.05), 0px 7px 16px rgba(104, 85, 39, 0.07)",
    navigationNone:
      "0px 0px 3px rgba(0, 0, 0, 0), 0px 7px 16px rgba(82, 62, 56, 0)",
    stampShadow: "0px 34px 60px rgba(0, 0, 0, 0.1)",
  },

  dropShadow: {
    navigation:
      "drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 7px 16px rgba(82, 62, 56, 0.07))",
  },

  outline: {
    base: {
      outline: "2px solid #D93075",
      offset: "2px",
    },
  },

  /**
   * When we have remove the `space` key we could rename this method to `space(6)`
   * | Index | Value |
   * |-------|-------|
   * | 0     | 0px   |
   * | 1     | 2px   |
   * | 2     | 4px   |
   * | 3     | 8px   |
   * | 4     | 12px  |
   * | 5     | 16px  |
   * | 6     | 24px  |
   * | 7     | 32px  |
   * | 8     | 40px  |
   * | 9     | 48px  |
   * | 10    | 56px  |
   * | 11    | 64px  |
   * | 12    | 72px  |
   * | 13    | 80px  |
   * | 14    | 88px  |
   * | 15    | 96px  |
   *
   */
  spacing(...offsets: Array<number | "auto">): string {
    const baseSize = [0, 2, 4, 8, 12, 16];

    return offsets
      .map((offset) => {
        if (offset === "auto") {
          return "auto";
        }

        if (offset < baseSize.length) {
          return `${baseSize[offset]}px`;
        }

        return `${16 + (offset - baseSize.length + 1) * 8}px`;
      })
      .join(" ");
  },
};

export default theme;
